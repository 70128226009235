






























































import {Component, Watch} from 'vue-property-decorator'
import _ from 'lodash'
import {debounce} from 'throttle-debounce'
import __ from '@/shared/helpers/__'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import http from '@/shared/http'
import IResponse from '@/shared/interfaces/modules/response.interface'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import {IFilter} from '@/shared/interfaces/classes/form.interfaces'
import {getQueryName} from '@/shared/helpers/query.helper'

@Component({
  methods: {__}
})
export default class SearchableField extends AbstractField {
  items: SelectItem[] = []
  searchKeyword: string | null = ''
  selectedItem: SelectItem | null | undefined = null
  page: number = 1
  lastPage: number = 1
  loading: boolean = false
  initialLoaded: boolean = false
  insideChange: boolean = false
  debouncedSearch = debounce(400, this.search)

  @Watch('items')
  private watchItemsChanges(): void {
    this.field.items = this.items
  }

  @Watch('searchKeyword')
  private start(): void {
    if (this.searchKeyword === null) return
    this.loading = true
    this.page = 1
    this.lastPage = 1
    if (this.value) {
      this.value = null
      this.selectedItem = null
    }
    this.debouncedSearch()
  }

  @Watch('selectedItem')
  private handleSelectedItem() {
    this.removeError()
    if (this.field.afterSelect) {
      this.field.afterSelect(this.selectedItem)
      this.selectedItem = null
      this.value = null
      this.searchKeyword = ''
    }

    if (this.field.onItemSelect) this.field.onItemSelect(this.selectedItem, this.fullKey)
  }

  @Watch('value')
  private test(): void {
    if (!this.insideChange) this.loadPredefined()
    this.insideChange = false
  }

  created() {
    this.loadPredefined()
  }

  async loadStartingData() {
    this.page = 1;
    this.lastPage = 1;

    await this.search()
  }

  changeValue(value: string | null): void {
    if (!value) value = null
    this.insideChange = true
    this.selectedItem = this.items.find((item: SelectItem) => item.value === value)
    if (this.selectedItem) this.items = [this.selectedItem]
    this.lastPage = 1
    this.page = 1
    if (!value) this.search()
    this.value = value
  }

  get showLoadMore(): boolean {
    return this.page < this.lastPage
  }

  loadMore(): void {
    this.page++;
    this.search(true)
  }

  setValue(value: string): void {
    this.value = value
    this.loadPredefined()
  }

  get autocompleteMenuProps(): any {
    let defaultProps: any = {
      maxHeight: 304,
    };

    if (this.$vuetify.breakpoint.smAndDown) {
      defaultProps.maxHeight = 130;
    }

    return defaultProps;
  }

  get attachId(): string {
    return `attach-${this.form ? _.snakeCase(this.form.formId) : Math.random().toString(36).slice(2)}-${_.snakeCase(this.fullKey)}`
  }

  private search(loadMore: boolean = false): Promise<any> {
    this.loading = true
    return http.get(this.generateEndpoint())
      .then((response: IResponse<Array<Model<IModelResponse>>>) => {
        let items = response.data.data
          .map((item: Model<IModelResponse>) => new SelectItem()
            .setValue(_.get(item, this.field.loadItemsProps.value))
            .setTitle(_.get(item, this.field.loadItemsProps.title) || '—')
            .setMeta(item)
          )

        if (loadMore) {
          items = [...this.items, ...items]

          if (response.data.data.length >= this.field.loadItemsProps.perPage) {
            this.lastPage++;
          }
        } else {
          if (response.data.data.length >= this.field.loadItemsProps.perPage) {
            this.lastPage = 2;
          }
        }

        this.items = items
        setTimeout(() => {
          const autocomplete: any = this.$refs.autocomplete
          if (!autocomplete) return
          autocomplete.onScroll()
          autocomplete.updateMenuDimensions()
        }, 100)
      })
      .finally(() => this.loading = false)
  }

  private loadPredefined(): void {
    if (!this.value) return

    if (this.form && this.form.entry && this.field.getInitialObjectKey) {
      const initialObject = _.get(this.form.entry, this.field.getInitialObjectKey(this.fullKey))

      if (initialObject) {
        this.selectedItem = new SelectItem()
          .setValue(_.get(initialObject, this.field.loadItemsProps.value))
          .setTitle(_.get(initialObject, this.field.loadItemsProps.title))
          .setMeta(initialObject)

        this.items.push(this.selectedItem)
        this.initialLoaded = true

        return
      }
    }

    this.loading = true
    let endpoint = `${this.field.loadItemsProps.endpoint}/${this.value}`

    if (this.field.loadItemsProps.endpoint) {
      endpoint += '?withTrashed'
    }

    http.get(endpoint)
      .then((response: IResponse<Model<IModelResponse>>) => response.data)
      .then((item: Model<IModelResponse>) => {
        this.selectedItem = new SelectItem()
          .setValue(_.get(item, this.field.loadItemsProps.value))
          .setTitle(_.get(item, this.field.loadItemsProps.title))
          .setMeta(item)
        this.items.push(this.selectedItem)
        this.initialLoaded = true
      })
      .catch(() => this.value = null)
      .finally(() => this.loading = false)
  }

  private generateEndpoint(): string {
    const searchParams = new URLSearchParams()

    if (this.searchKeyword) searchParams.set(`filter-like-${this.field.loadItemsProps.title}`, this.searchKeyword)
    searchParams.set('per_page', this.field.loadItemsProps.perPage.toString())
    searchParams.set('page', this.page.toString())

    if (this.field.loadItemsProps.filters) {
      this.field.loadItemsProps.filters.forEach((filter: IFilter) => {
        searchParams.set(getQueryName(filter.type, filter.name), filter.value)
      })
    }

    if (this.field.loadItemsProps.params) {
      Object.keys(this.field.loadItemsProps.params).forEach((key: string) => {
        searchParams.set(key, this.field.loadItemsProps.params[key])
      })
    }

    if (this.field.loadItemsProps.withTrashed) {
      searchParams.set('withTrashed', '')
    }

    let endpoint = this.field.loadItemsProps.endpoint
    if (this.field.loadItemsProps.additionalEndpoint) endpoint += `/${this.field.loadItemsProps.additionalEndpoint}`

    return endpoint + `?${searchParams.toString()}`
  }

  get autocompleteRef(): any {
    return this.$refs.autocomplete
  }
}
